import { NGXLogger } from 'ngx-logger';
import { IProductVariant } from './../_models/product-variant';
import { ProductVariant } from './../_models/product-variant';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ModuleData } from '../_services/module-data';
import { DEFINITIONS } from './../definitions';

interface IProductVariantBackend {
  typecode: string;
  materialnumbers: string[];
}

@Injectable({
  providedIn: 'root'
})

export class ProductVariantService {

  // state texts
  private generatorApiUrl: string;

  constructor(private logger: NGXLogger,
              private http: HttpClient) {
    this.logger.info('(product-variant.service) => constructor()');
    this.generatorApiUrl = DEFINITIONS.urlGeneratorApi + '/product-variants';
    if (window.location.origin.startsWith('http://localhost:4200')) {
      this.generatorApiUrl = 'http://localhost:8000' + this.generatorApiUrl;
    }
  }

  getVariants(moduleData: ModuleData, search: string): Observable<IProductVariant[]> {
    return new Observable<IProductVariant[]>((subscriber) => {
        //get query params
        let phcodes = moduleData ? moduleData.phcodes : '';
        let matnumber = search.startsWith('R9') ? search : '';
        let typecode;
        if( moduleData) {
          typecode = matnumber ? moduleData.moduleId : moduleData.moduleId + search;
        } else {
          typecode = matnumber ? '' : search;
        }
        //send GET request
        this.http.get<IProductVariantBackend[]>(
        this.generatorApiUrl + '?phcodes=' + phcodes + '&typecode=' + encodeURIComponent(typecode)  + '&matnumber=' + encodeURIComponent(matnumber))
        .subscribe(
          // success
          (response) => {
            const typeCodeSearchMode = !(search.toUpperCase().startsWith('R9'));
            const productVariants = this.expandVariants(response, typeCodeSearchMode);
            subscriber.next(productVariants);
            subscriber.complete();
            return;
          },
          // error
          (response) => {
            subscriber.error(response);
            return;
          });
    });
  }

  expandVariants(variants: IProductVariantBackend[], typeCodeSearchMode: boolean): IProductVariant[] {
    const productVariants = new Array<IProductVariant>();
    for (const variant of variants) {
      if (typeCodeSearchMode) {
        const matnumber = variant.materialnumbers.length === 1 ? variant.materialnumbers[0].toUpperCase() : '';
        productVariants.push(new ProductVariant({ typecode: variant.typecode.toUpperCase(), materialnumber: matnumber }));
      } else {
        for (const matnumber of variant.materialnumbers) {
          productVariants.push(new ProductVariant({ typecode: variant.typecode.toUpperCase(), materialnumber: matnumber.toUpperCase() }));
        }
      }
    }
    return productVariants;
  }

}
