import { NGXLogger } from 'ngx-logger';
import { Injectable } from '@angular/core';
import { NavigationService } from '../_services/navigation.service';
import { DEFINITIONS } from '../definitions';

@Injectable({
  providedIn: 'root'
})

export class DataPortalIFrameService {
  private _type: string;
  private _token: string;
  private _language: string;
  private _eplanVersion: string;
  private _callbackUrl: string;
  private _rootPartNumber: string;
  private _orderNumber: string;
  private _typeNumber: string;
  private _customData: string;
  private _filterTargetMarket: string;
  private _filterEplanCatalogSelectionName: string;
  private _iFrameSource: Window;
  private _messageEventIsRegistered = false;
  private _iFrameInitialized = false;
  private _iFrameDataContextInitialized = false;


  constructor(private logger: NGXLogger,
              private navigationService: NavigationService) {
    this.logger.info('(data-portal-iframe.service) => constructor()');
  }


  public handleDataPortalCall(): Promise<boolean> {
    if (this._messageEventIsRegistered) {
      this.logger.info('(data-portal-iframe.service) handleDataPortalCall(): CANCEL - EVENT LISTENER already registered')
      return;
    }
    this._messageEventIsRegistered = true;
    this.logger.info('(data-portal-iframe.service) handleDataPortalCall(): ADD EVENT LISTENER for PostMessage');

    return new Promise((resolve) => {
      window.addEventListener('message', (e) => {
        if (this._iFrameInitialized)  {
          if (e.data !== undefined && e.data.type === 'data') {
            this.logger.info('(data-portal-iframe.service) handleDataPortalCall(): DATA arrived');
            this.logger.info('(data-portal-iframe.service) DATA => ' + JSON.stringify(e.data));

            // discard further DATA calls (allow only one DATA call)
            if (this._iFrameDataContextInitialized) {
              this.logger.info('(data-portal-iframe.service) Data are already initialized => !!!!DISCARD DATA call!!!!'); 
              return;
            }
            const body = e.data.body;
            this._type = body.type;
            this._token = body.token;
            this._language = body.language;
            this._eplanVersion = body.eplan_version;
            this._callbackUrl = body.follow_up_url;
            this._rootPartNumber = body.root_part_number;
            this._orderNumber = body.order_number;
            this._typeNumber = body.type_number;
            this._customData = body.custom_data;

            // call from EPLAN P8 or standard browser
            const showDownloads = (this._eplanVersion && (this._eplanVersion !== '' && this._eplanVersion !== '1.9.11')) ? false : true;

            // set generator operation mode and eplan version
            if( showDownloads) {
              sessionStorage.setItem(DEFINITIONS.sessParGenMode, DEFINITIONS.modeBrowserEmbedded);
              sessionStorage.removeItem(DEFINITIONS.sessParEplanVersion);
            } else {
              sessionStorage.setItem(DEFINITIONS.sessParGenMode, DEFINITIONS.modeEplanEmbedded);
              sessionStorage.setItem(DEFINITIONS.sessParEplanVersion, this._eplanVersion);
            }

            // navigate to generator route an set route queryParams
            this.navigationService.navigate('/generator', {
              moduleId: this.moduleId,
              downloads: showDownloads,
              language: this._language,
              reloadRoute: true
            });

            this._iFrameDataContextInitialized = true;
            resolve(true);
          }
        } else if (e.data !== undefined && e.data.type === 'status' && e.data.body === 'init') {

          this.logger.info('(data-portal-iframe.service) handleDataPortalCall(): INIT arrived');
          this.logger.info('(data-portal-iframe.service) DATA => ' + JSON.stringify(e.data));

          // if (e.source instanceof Window ) {    // Do not use this => provides 'true' for Firefox and 'false' for Chrome environment!
          if (!this._iFrameInitialized) {
            if (typeof e.source.postMessage === 'function') {
              this.logger.info('(data-portal-iframe.service) handleDataPortalCall(): !!!Do INIT RESPONSE!!!');
              this._iFrameInitialized = true;
              this._iFrameSource = e.source as Window;
              this._iFrameSource.postMessage({ type: 'status', body: 'init' }, '*');
            }
          }
        }
      });
    });
  }

  get moduleId(): string {
    if (this._rootPartNumber === undefined) {
      return undefined;
    }

    // delete 'BOS.' and '**'
    let moduleId = this._rootPartNumber;
    if (moduleId.indexOf('BOS.') === 0) {
      moduleId = moduleId.substring(4, moduleId.length);
    }
    if (moduleId.endsWith('**')) {
      moduleId = moduleId.substring(0, moduleId.length - 2);
    }
    return moduleId;
  }
}
