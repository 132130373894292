export interface IProductVariant {
  typecode: string;
  materialnumber: string;
}

export class ProductVariant implements IProductVariant {
  typecode: string;
  materialnumber: string;

  constructor(obj: IProductVariant) {
    this.typecode = obj.typecode;
    this.materialnumber = obj.materialnumber;
  }
}
